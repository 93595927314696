<template>
  <v-container grid-list-lg>
    <div>
        <h1>Salutation!</h1>
        <p>My name is Norman and I am a technology enthusiast.<br/> Over the past decade I have been working in IT with love, passion and on occasion strong tension headaches. 
         Nonetheless I became obsessed with learning new things, writing code, and occasionally getting the feeling of creating something new with my bare hands.
          Yes, coding can be quite a challenge and over the years I had to learn it the hard way. Screwing up databases, killing production systems and fighting managers was just part of my junior developer's journey. </p>
          <p> After finishing my Master's Degree in Computer Science in 2015, I began working as a fulltime Developer gaining more and more experience with Spring Boot, Python and Camunda/BPMN.<br/>
                      Nowadays, I am more versatile and I confidently challenge software projects to the very edge on a regular basis.</p>
    </div>
  </v-container>
</template>

<script>
export default {
    name: 'HomeDetails'
};
</script>

<style>
</style>